import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import GithubLink from "../components/GithubLink"
import ImageGallery from "../components/ImageGallery"

import WebappImg from "../images/project_thumbnails/sw_webapp.png"
import RebuildErrorImg from "../images/sw_webapp/rebuild_error_highlight.png"

const imgs = [
    {
        img: WebappImg,
        alt: "SolidWorks model and corresponding webapp display.",
    },
    {
        img: RebuildErrorImg,
        alt: "Items with rebuild errors are highlighted red for visibility",
    },
];

const SolidWorks = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>SolidWorks WebApp via Python3</h1>
            {GithubLink("https://github.com/Gautreaux/SolidProxy")}
            <p><em>Most of this is documented in more detail in the <a href="https://github.com/Gautreaux/SolidProxy">Github Repo</a>. This writeup is more motivation focused.</em></p>
            <p>SolidWorks is professional software for 3D design. It runs only on Windows and is something that a normal software engineer would never interact with (or probably even know exists). I've done alot of SolidWorks and I quite enjoy it. Something that I always found was that I spent much time doing very few things. As a software engineer, this of course screams for automation.</p>
            <p>SolidWorks has a very good API. Furthermore, it is all accessible via the Windows Component Object Model (COM). Practically any button or setting a user can change in SolidWorks, a program can change via the COM. However, there is one drawback: you are locked into the Windows COM. Traditionally, this means C#, (Visual) C++, or Visual Basic. These languages are not known for being popular. As compared to Python, the (free) resources available for C# are abysmal.</p>
            <p>Of course, there is a Python library for interacting with the COM. Thus, is it possible to connect to SolidWorks via this library and call the SolidWorks API from Python? Turns out this is very possible.</p>
            <p>As a proof of concept, I developed a small webapp which display the currently open files and some simple summary statistics. There is more documentation available in the <a href="https://github.com/Gautreaux/SolidProxy">Github Repo</a>. Regardless, it works, and it works very well. I've proven that you can connect to SolidWorks and populate information from it (via the COM API) into a Django Database. From here the only direction is up. You could setup a CI/CD pipeline which ensures that the model passes all rebuild checks. Likewise, you can setup regression tests: making sure that edits to a commonly used subassembly do not introduce any breaking changes. The world of Python has come to SolidWorks, and the potential is unimaginable.</p>
            <hr/>
            <p>This was also my first foray into using C#. I like it. It was interesting. Most of what I implemented in Python I tested first in C#. As the libraries are for C#, having (some) compile time checks and proper documentation makes iterating faster than just shooting in the dark in Python.</p>
            <hr/>
            <ImageGallery value={imgs}/>
            {FooterBar()}
        </div>
    )
};

export default SolidWorks;